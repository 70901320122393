import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { MODULE } from '@core/tokens/module.token';
import { Observable } from 'rxjs';

export interface FileSizeResponse {
    valid: boolean;
    maxFileUpload: number | string;
}

export interface ListHistory {
    page: number;
    type: string;
}

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    private lastListHistory: ListHistory;
    private previousUrl: string;
    private currentUrl: string;

    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MODULE) private module: LoginMode,
        private router: Router,
    ) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public saveListHistory(page: number, type: string): void {
        this.lastListHistory = <ListHistory>{
            page: page,
            type: type,
        };
    }

    public getListPageHistory(type: string, nextPage: number): number {
        if (!type) {
            return nextPage;
        }
        const prevPage = this.getPreviousUrl();
        if (
            prevPage?.includes(`${type}/`) &&
            !prevPage.includes(`/new`) &&
            this.lastListHistory &&
            this.lastListHistory.page > 0
        ) {
            const page = this.lastListHistory.page;
            this.previousUrl = this.router.url;
            this.lastListHistory = null;
            return page;
        } else {
            this.lastListHistory = null;
        }
        return nextPage;
    }

    private getPreviousUrl(): string {
        return this.previousUrl;
    }

    public isValidFileSize(size: number): Observable<any> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `utils/is-valid-file-size`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.post(url, { size: size }, { headers });
    }

    public scrollTop(): void {
        const field = document.querySelector('.mat-sidenav-content');
        if (field) {
            field.scroll(0, 0);
        }
    }

    public scrollToElement(
        element: Element,
        position: ScrollLogicalPosition = 'center',
    ): void {
        element.scrollIntoView({
            block: position,
            behavior: 'smooth',
        });
    }
}
